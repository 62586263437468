import { useState, useEffect, useRef, useLayoutEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { motion, useInView /*, useMotionValue*/ } from "framer-motion";

import AppleMenu from "../components/appleMenu";

//import Chevron from "../assets/chevron"

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export default function Real() {
  //const [active, setActive] = useState(0);
  const [menuServices, changeMenuServices] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);
  const [firstSlide, toggleFirstSlide] = useState(true);

  //const togglerFrameRef = useRef(null)
  const ref = useRef(null);
  const termsRef = useRef(false);
  //const actualPos = useRef(0)

  const [width, height] = useWindowSize();
  const mobile = width <= 678;

  const isVisible = useInView(ref);

  const constraintsRef = useRef(null);
  //const activeRef = useRef(null);
  const menuServicesRef = useRef(null);

  //const x = useMotionValue(0);
  //const drag = useMotionValue(0);

  useEffect(() => {
    menuServicesRef.current = menuServices;
    termsRef.current = menuServices === 3 ? true : false;
    toggleFirstSlide(true);
  }, [menuServices]);

  useEffect(() => {
    let nextArrow = document.getElementsByClassName("swiper-button-next");
    let prevArrow = document.getElementsByClassName("swiper-button-prev");
    if (nextArrow[0]) nextArrow[0].classList.add("clickable");
    if (prevArrow[0]) prevArrow[0].classList.add("clickable");
  }, [menuServices]);

  /*useEffect(() => {
        const onWheel = (e) => {
            let screenH = window.screen.height
            let advance = ref.current.offsetTop - e.pageY

            if (termsRef.current) return

            console.log(ref.current.offsetTop)
            //console.log(e)

            if (false && menuServicesRef.current !== 0) {
                setActive(200)
                changeMenuServices(0)
            } else if (false && e.offsetY > window.innerHeight * 4) {
                setActive(200)
                changeMenuServices(3)
            } else {

              if (activeRef.current < -1*window.innerHeight) {
                if (menuServicesRef.current === 0) {
                  changeMenuServices(1)
                } else if (menuServicesRef.current === 1) {
                  changeMenuServices(2)
                } else if (menuServicesRef.current === 2) {
                  changeMenuServices(3)
                }
                setActive(200)
              } else if (activeRef.current > window.innerHeight) {
                  if (menuServicesRef.current === 1) {
                      changeMenuServices(0)
                  } else if (menuServicesRef.current === 2) {
                      changeMenuServices(1)
                  } else if (menuServicesRef.current === 3) {
                      changeMenuServices(2)
                  }
                  setActive(200)
              } else {
                  setActive(activeRef.current - e.deltaY)
              }
            }

        }

        ref.current.addEventListener('wheel', onWheel, { smooth: true })

        return () => {
            ref.current?.removeEventListener('wheel', onWheel, { });
        }
    }, [])*/

  const realSliderWebsites = [
    {
      type: 0,
      name: "NoMatter.png",
      video: "videoMobileNoMatter.mp4",
      animParams: { delay: 1 },
    },
    {
      type: 0,
      name: "Biyon.png",
      video: "videoMobileBypto.mp4",
      animParams: { delay: 1 },
    },
    {
      type: 0,
      name: "VS.png",
      video: "videoMobileVS.mp4",
      animParams: { delay: 1 },
    },
    {
      type: 0,
      name: "Connect.png",
      video: "videoMobileChillz.mp4",
      animParams: { delay: 1 },
    },
    {
      type: 0,
      name: "Sbc.png",
      video: "videoMobileSBC1.mp4",
      animParams: { delay: 1 },
    },
    {
      type: 0,
      name: "Sbc2.png",
      video: "videoMobileSBC2.mp4",
      animParams: { delay: 1 },
    },
    {
      type: 0,
      name: "NoMatter.png",
      video: "videoMobileNoMatter.mp4",
      animParams: { delay: 1 },
    },
    {
      type: 0,
      name: "Biyon.png",
      video: "videoMobileBypto.mp4",
      animParams: { delay: 1 },
    },
    {
      type: 0,
      name: "VS.png",
      video: "videoMobileVS.mp4",
      animParams: { delay: 1 },
    },
    {
      type: 0,
      name: "Connect.png",
      video: "videoMobileChillz.mp4",
      animParams: { delay: 1 },
    },
    {
      type: 0,
      name: "Sbc.png",
      video: "videoMobileSBC1.mp4",
      animParams: { delay: 1 },
    },
    {
      type: 0,
      name: "Sbc2.png",
      video: "videoMobileSBC2.mp4",
      animParams: { delay: 1 },
    },
    {
      type: 1,
      name: "NoMatter.png",
      video: "videoNoMatter.mp4",
      animParams: { delay: 1 },
    },
    {
      type: 1,
      name: "Sbc.png",
      video: "videoSBC.mp4",
      animParams: { delay: 1 },
    },
    {
      type: 1,
      name: "Bypto.png",
      video: "videoBypto.mp4",
      animParams: { delay: 1 },
    },
    {
      type: 1,
      name: "VS.png",
      video: "videoVS.mp4",
      animParams: { delay: 1 },
    },
    {
      type: 1,
      name: "Segunda.png",
      video: "videoSegunda.mp4",
      animParams: { delay: 1 },
    },
    {
      type: 1,
      name: "Adult.png",
      video: "videoAdult.mp4",
      animParams: { delay: 1 },
    },
    {
      type: 1,
      name: "Hashing.png",
      video: "videoHashing.mp4",
      animParams: { delay: 1 },
    },
    {
      type: 2,
      name: "Sbc.jpg",
      animParams: { delay: 1 },
    },
    {
      type: 2,
      name: "Hima.jpg",
      animParams: { delay: 1 },
    },
    {
      type: 2,
      name: "NoMatter.jpg",
      animParams: { delay: 1 },
    },
    {
      type: 2,
      name: "Francis.jpg",
      animParams: { delay: 1 },
    },
    {
      type: 2,
      name: "VS.jpg",
      animParams: { delay: 1 },
    },
    {
      type: 2,
      name: "Segunda.jpg",
      animParams: { delay: 1 },
    },
    {
      type: 2,
      name: "Rei.jpg",
      animParams: { delay: 1 },
    },
    {
      type: 2,
      name: "Vanish.jpg",
      animParams: { delay: 1 },
    },
    {
      type: 2,
      name: "Panthera.jpg",
      animParams: { delay: 1 },
    },
  ];

  function isOdd(num) {
    return num % 2;
  }

  function generateRandomLists() {
    const result = [];

    function getRandomSign(isZeroAccepted = true) {
      let random = Math.random();
      return random < 0.3 ? 1 : random > 0.6 ? -1 : isZeroAccepted ? 0 : 1;
    }

    for (let i = 0; i < 10; i++) {
      let min = 2000;
      let max = 3000;

      let a = getRandomNumberInRange(min, max) * getRandomSign();
      let b = getRandomNumberInRange(min, max) * getRandomSign(false);

      result.push([a, b]);
    }

    return result;
  }

  function getRandomNumberInRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function handleFirstControlledLeftSlider() {
    return changeMenuServices((v) => {
      //console.log(v)
      if (window.innerWidth <= 480 && v === 2) {
        setTimeout(() => {
          changeMenuServices(0);
        }, 5000);
      }
      return v + 1;
    });
  }

  function handleSlideChange(e) {
    //console.log(e);
    toggleFirstSlide(false);
  }

  function handleSliderIndexCange(e) {
    //console.log("AAA", e.realIndex);
    setActiveSlide(e.realIndex);
  }

  const randomPairs = generateRandomLists();
  //console.log(randomPairs);

  const terms = [
    {
      word: "Marketing",
      targetX: randomPairs[0][0],
      targetY: randomPairs[0][1],
    },
    {
      word: "Motion graphics",
      targetX: randomPairs[1][0],
      targetY: randomPairs[1][1],
    },
    { word: "Photo", targetX: randomPairs[2][0], targetY: randomPairs[2][1] },
    { word: "Video", targetX: randomPairs[3][0], targetY: randomPairs[3][1] },
    {
      word: "Influencer",
      targetX: randomPairs[4][0],
      targetY: randomPairs[4][1],
    },
    {
      word: "Strategy",
      targetX: randomPairs[5][0],
      targetY: randomPairs[5][1],
    },
    {
      word: "Development",
      targetX: randomPairs[6][0],
      targetY: randomPairs[6][1],
    },
    {
      word: "Identity",
      targetX: randomPairs[7][0],
      targetY: randomPairs[7][1],
    },
    {
      word: "Social Media Management",
      targetX: randomPairs[8][0],
      targetY: randomPairs[8][1],
    },
    {
      word: "UI/UX Design",
      targetX: randomPairs[9][0],
      targetY: randomPairs[9][1],
    },
  ];

  let iOfType = 0;

  let slidesPhone = [];
  let slidesPc = [];
  let slidesView = [];

  realSliderWebsites.forEach((item, i) => {
    if (item.type === 0) {
      slidesPhone.push(
        <DevicedView
          key={i}
          type={menuServices}
          item={item}
          i={i}
          iOfType={iOfType}
          sWidth={width}
          activeSlide={activeSlide}
        />,
      );
    } else if (item.type === 1) {
      slidesPc.push(
        <DevicedView
          key={i}
          type={menuServices}
          item={item}
          i={i}
          iOfType={iOfType}
          sWidth={width}
          activeSlide={activeSlide}
        />,
      );
    } else if (item.type === 2) {
      slidesView.push(
        <DevicedView
          key={i}
          type={menuServices}
          item={item}
          i={i}
          iOfType={iOfType}
        />,
      );
    }

    if (item.type === menuServices) iOfType += 1;

    /*if (item.type === menuServices) {
      return slides.push(<DevicedView key={i} type={menuServices} item={item} i={i} iOfType={iOfType}/>)
    }*/
  });

  return (
    <section className="real" ref={ref}>
      <AppleMenu active={menuServices} setActive={changeMenuServices} mobile={mobile} />

      {isVisible && (
        <div className="realSlider">
          {menuServices === 0 && (
            <Swiper
              breakpoints={{
                2700: { slidesPerView: 6 },
                1800: { slidesPerView: 5 },
                1200: { slidesPerView: 4 },
                800: { slidesPerView: 3 },
                500: { slidesPerView: 2 },
                0: { slidesPerView: 1 },
              }}
              breakpointsBase="container"
              centeredSlides={true}
              slidesPerView={3}
              spaceBetween={10}
              slidesOffsetBefore={mobile ? 0 : 150}
              allowSlidePrev={firstSlide ? false : true}
              loop={true}
              onSlideNextTransitionEnd={handleSlideChange}
              onActiveIndexChange={handleSliderIndexCange}
              navigation={{ disabledClass: "hidden" }}
              modules={[Pagination, Navigation]}
              slideNextClass="clickable"
              className={`${firstSlide ? "firstSlideMode" : ""}`}
            >
              {slidesPhone.map((item, i) => (
                <SwiperSlide className="cardFrame" key={i} virtualIndex={i}>
                  {item}
                </SwiperSlide>
              ))}
              <div className={`shadowLeft ${firstSlide ? "hidden" : ""}`} />
              <div className="shadowRight" />
            </Swiper>
          )}

          {menuServices === 1 && (
            <Swiper
              breakpoints={{
                3200: { slidesPerView: 5 },
                2200: { slidesPerView: 4 },
                1200: { slidesPerView: 3 },
                600: { slidesPerView: 2 },
                0: { slidesPerView: 1 },
              }}
              breakpointsBase="container"
              centeredSlides={true}
              spaceBetween={10}
              slidesOffsetBefore={mobile ? 0 : 150}
              allowSlidePrev={firstSlide ? false : true}
              loop={true}
              onSlideNextTransitionEnd={handleSlideChange}
              onActiveIndexChange={handleSliderIndexCange}
              navigation={{ disabledClass: "hidden" }}
              modules={[Pagination, Navigation]}
              slideNextClass="clickable"
              className={`${firstSlide ? "firstSlideMode" : ""}`}
            >
              {slidesPc.map((item, i) => (
                <SwiperSlide className="cardFrame" key={i} virtualIndex={i}>
                  {item}
                </SwiperSlide>
              ))}
              <div className="shadowLeft" />
              <div className="shadowRight" />
            </Swiper>
          )}

          {menuServices === 2 && (
            <Swiper
              breakpoints={{
                2000: { slidesPerView: 4 },
                1200: { slidesPerView: 3 },
                600: { slidesPerView: 2 },
                0: { slidesPerView: 1 },
              }}
              breakpointsBase="container"
              centeredSlides={true}
              spaceBetween={10}
              slidesOffsetBefore={mobile ? 0 : 150}
              allowSlidePrev={firstSlide ? false : true}
              loop={true}
              onSlideNextTransitionEnd={handleSlideChange}
              navigation={{ disabledClass: "hidden" }}
              modules={[Pagination, Navigation]}
              slideNextClass="clickable"
              className={`${firstSlide ? "firstSlideMode" : ""}`}
            >
              {slidesView.map((item, i) => (
                <SwiperSlide className="cardFrame" key={i} virtualIndex={i}>
                  {item}
                </SwiperSlide>
              ))}
              <div className="shadowLeft" />
              <div className="shadowRight" />
            </Swiper>
          )}

          {menuServices === 3 && (
            <div
              className="sliderContainer"
              style={{ padding: menuServices === 3 ? "0px" : "" }}
              ref={constraintsRef}
            >
              <div className="terms">
                {terms.map((item, i) => (
                  <motion.div
                    whileInView={{
                      filter: [null, null, "blur(30px)"],
                      scale: [0, 3],
                      translateX: [0, item.targetX / 1.2],
                      translateY: [0, item.targetY / 2.5],
                    }}
                    transition={{
                      duration: 4,
                      ease: "easeIn",
                      times: [0, 0.3, 1],
                      repeat: Infinity,
                      repeatDelay: 1,
                      delay: isOdd(i) ? i * 0.75 : i,
                    }}
                  >
                    #{item.word}
                  </motion.div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      {/*mobile && <AppleMenu active={menuServices} setActive={changeMenuServices}/>*/}
    </section>
  );
}

const DevicedView = (p) => {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    if (p.sWidth < 500 && p.activeSlide === p.iOfType && !hovered) {
      setHovered(true)
    } else if (hovered) {
      setHovered(false)
    }
  }, [p.activeSlide])

  function toggleHovered(val) {
    if (p.sWidth >= 500) {
      setHovered(val);
    }
  }

  if (p.type === 0) {
    return (
      <motion.div
        className="card"
        initial={{ scale: 0.7, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        //viewport={{ once: false }}
        transition={{ delay: p.iOfType * 0.1, duration: 0.3 }}
        key={p.item.name}
        onMouseOver={() => toggleHovered(true)}
        onMouseOut={() => toggleHovered(false)}
      >
        <img className="logo" src={`ci${p.item.name}`} alt="logo" />
        <FramedDevice
          proj={p.item.name}
          type={0}
          video={p.item.video}
          hovered={hovered}
        />
      </motion.div>
    );
  } else if (p.type === 1) {
    return (
      <motion.div
        className="card web"
        initial={{ scale: 0.7, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        //viewport={{ once: false }}
        transition={{ delay: p.iOfType * 0.1, duration: 0.3 }}
        key={p.item.name}
        onMouseOver={() => toggleHovered(true)}
        onMouseOut={() => toggleHovered(false)}
      >
        <img className="logo" src={`ciMac${p.item.name}`} alt="logo" />
        <FramedDevice
          proj={p.item.name}
          type={1}
          video={p.item.video}
          hovered={hovered}
        />
      </motion.div>
    );
  } else {
    return (
      <motion.div
        className="card brand"
        initial={{ scale: 0.7, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        //viewport={{ once: false }}
        transition={{ delay: p.iOfType * 0.1, duration: 0.3 }}
        key={p.i}
        style={{
          backgroundImage: `url(/viewBrand${p.item.name})`,
          paddingBottom: "116.5%",
        }}
      ></motion.div>
    );
  }
};

function FramedDevice(p) {
  const videoRef = useRef(null);
  const isPlayingRef = useRef(null);

  useEffect(() => {
    let hovered = p.hovered;

    //https://stackoverflow.com/questions/36803176/how-to-prevent-the-play-request-was-interrupted-by-a-call-to-pause-error
    /*var isPlaying = true
    video.onplaying = function() { isPlaying = true }
    video.onpause = function() { isPlaying = false }
    async function playVid() {      
        if (video.paused && !isPlaying) return video.play();
    }
    function pauseVid() {
      if (!video.paused && isPlaying) video.pause();
    }*/

    if (hovered) {
      changeVideoState(true)
    } else {
      changeVideoState(false)
    }
  }, [p.hovered]);


  function changeVideoState(playIfTrue) {
    if (playIfTrue) {
      if (videoRef.current.paused) setTimeout(() => videoRef.current.play(), 100)
    } else {
      setTimeout(() => videoRef.current.pause(), 100);
    }
  }

  if (p.type === 0) {
    return (
      <div className={`iphone frame ${p.hovered ? "active" : ""}`}>
        <img src="frameIphone.png" alt="iphone frame" />
        <img src={`view${p.proj}`} alt={p.proj} />

        <video loop muted /*preload="auto"*/ playsInline ref={videoRef}>
          <source src={p.video} type="video/mp4" />
        </video>

        <img src="frameIphone.png" alt="iphone frame" className="shadow" />
      </div>
    );
  } else if (p.type === 1) {
    return (
      <div
        className={`mac frame ${p.hovered ? "active" : ""}`}
        onMouseEnter={() => toggleHovered(true)}
        onMouseLeave={() => toggleHovered(false)}
      >
        <img src="frameMac.png" alt="Mac frame" />
        <img src={`viewMac${p.proj}`} alt={p.proj} />

        <video loop muted /*preload="auto"*/ playsInline ref={videoRef}>
          <source src={p.video} type="video/mp4" />
        </video>

        <img src="frameMac.png" alt="mac frame" className="shadow" />
      </div>
    );
  }
}

