import { useEffect, useState, useRef, useLayoutEffect } from "react";

import { Trans, useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import ScrollDown from "../components/ScrollDown";
import ScrolledText from "../components/ScrolledText";
import WeAre from "../components/WeAre";
import Real from "../components/Real";

import IconBrain from "../assets/brain";
import IconClever from "../assets/clever";
import IconGrowth from "../assets/growth";
import IconUserCentric from "../assets/userCentric";

import "../styles/landing.scss";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function toggleClassOnPin(className, pinnedClass, ratio) {
  const elements = document.querySelectorAll(`.${className}`);
  const entry1 = elements[0];
  const entry2 = elements[1];

  const observerCallback = (entries, observer) => {
    entries.forEach((entry) => {
      const notPinned = entry.intersectionRatio < ratio;

      if (entry.target.id === "ft2") {
        if (notPinned) {
          entry1.classList.remove(pinnedClass);
        } else {
          entry1.classList.add(pinnedClass);
        }
      } else if (entry.target.id === "ft3") {
        if (notPinned) {
          entry2.classList.remove(pinnedClass);
        } else {
          entry2.classList.add(pinnedClass);
        }
      }
    });
  };

  const observerOptions = { threshold: ratio };

  const observer = new IntersectionObserver(observerCallback, observerOptions);

  elements.forEach((element) => {
    observer.observe(element);
  });
}

export default function Landing() {
  const { t, i18n } = useTranslation();

  const heroRef = useRef(null);
  const featuresCard1 = useRef(null);

  const [width, height] = useWindowSize();
  const mobile = width <= 678;
  const tablette = width <= 1000;

  useEffect(() => {
    toggleClassOnPin("featuresCard", "pinned", 0.5);
    //toggleClassOnPin('real', 'showwww', .5)
  }, []);

  function scrollToTopFeatured(e) {
    if (e.target.className === "card-hover") return window.scrollTo(0, 0);
    else return;
  }

  let serviceHeadText = t("expected.sub").split(" ");

  return (
    <div className="index landing">
      <ScrollDown hideOnMobile appTargetRef={heroRef} />

      {mobile ? (
        <div>
          <section className="hero" id="hero" ref={heroRef}>
            <div className="layer" />
            <video autoPlay muted="true" loop preload="auto" playsInline>
              <source src="/heroMobile.mp4" type="video/mp4" />
            </video>
          </section>
          <WeAre mobile={mobile} />
        </div>
      ) : (
        <>
          <section className="hero" id="hero" ref={heroRef}>
            <div className="layer" />
            <video autoPlay muted="true" loop preload="auto" playsInline>
              <source src="/hero.mp4" type="video/mp4" />
            </video>
          </section>
          <WeAre mobile={mobile} />
        </>
      )}

      <section className="services">
        <div className="expect">
          <div className="head">
            <h3>
              <Trans i18nKey="expected.title" components={{ br: <br /> }} />
            </h3>

            <div>
              {serviceHeadText.map((el, i) => (
                <motion.span
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{
                    duration: 0.3,
                    delay: i / 18,
                  }}
                  key={i}
                >
                  {el === "<br/>" ? <br /> : el}{" "}
                </motion.span>
              ))}
            </div>

            {/*<motion.div
              initial={tablette ? {} : { width: 0 }}
              whileInView={{ width: "100%" }}
              viewport={{ once: false }}
              transition={{ duration: 2.5, delay: .5 }}
            >
              <p>We take your brand to the next level, focus on the intersections with the users, creation of professional, creative and functional content.</p>
            </motion.div>*/}
          </div>

          <div className="cards">
            <div className="cardLine">
              <div className="card">
                <IconBrain />
                <b>
                  <Trans
                    i18nKey="expected.emotions"
                    components={{ br: <br /> }}
                  />
                </b>
              </div>
            </div>

            <div className="cardLine two">
              <div className="card">
                <IconUserCentric />
                <b>
                  <Trans i18nKey="expected.perso" components={{ br: <br /> }} />
                </b>
              </div>
            </div>

            <div className="cardLine three">
              <div className="card">
                <IconGrowth />
                <b>
                  <Trans
                    i18nKey="expected.strength"
                    components={{ br: <br /> }}
                  />
                </b>
              </div>
            </div>

            <div className="cardLine four">
              <div className="card">
                <IconClever />
                <b>
                  <Trans i18nKey="expected.funct" components={{ br: <br /> }} />
                </b>
              </div>
            </div>
          </div>
        </div>

        <ScrolledText />
      </section>

      <div>
        <Real />

        {/*
        <section className="featured">
          <div className="headFrame">
            <div className="head">
              <motion.h2
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ delay: 0.25, duration: 0.5 }}
                //exit={{ scale: 0, y: -200}}
              >
                <Trans i18nKey="featuredProjects.title" components={{ br: <br/> }}/>
              </motion.h2>
              <motion.p
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.5, duration: 0.3 }}
                viewport={{ once: true }}
                //exit={{ scale: 0, y: -100}}      
              >
                <Trans i18nKey="featuredProjects.sub" components={{ br: <br/> }}/>
              </motion.p>
            </div>
          </div>

          <div className="cards" onClick={scrollToTopFeatured}>
            <Link to="clients" className="card featuresCard" id="ft1" ref={featuresCard1}>
              <img src="hpNoMatter.jpg" />
              <p className="card-hover">
                <Trans i18nKey="featuredProjects.featured"/><br />
                <span>No Matter</span>
              </p>
            </Link>

            <Link to="clients" className="card featuresCard" id="ft2">
              <img src="hpSbc.jpg" />
              <p className="card-hover">
                <Trans i18nKey="featuredProjects.featured"/><br />
                <span>SBC</span>
              </p>
            </Link>

            <Link to="clients" className="card featuresCard" id="ft3" >
              <img src="hpHimalight.jpg" />
              <p className="card-hover">
                <Trans i18nKey="featuredProjects.featured"/><br />
                <span>Himalight</span>
              </p>
            </Link>
          </div>
        </section>
        */}
      </div>

      <section className="getTouch">
        <motion.div
          className="borderFrame"
          initial={{
            scale: 1,
          }}
          whileInView={{
            scale: 0.9,
          }}
          viewport={{ once: false }}
          transition={{
            duration: 2,
            delay: 0.5,
          }}
        />

        <motion.div
          className="textFrame"
          initial={{
            scale: 0.8,
            opacity: 0,
          }}
          whileInView={{
            scale: 1,
            opacity: 1,
          }}
          viewport={{ once: false }}
          transition={{
            duration: 1,
            delay: 0.1,
          }}
        >
          <h2>
            <Trans i18nKey="cta.projectInMind" components={{ br: <br />, span: <span/> }} />
            <br />
            <Link to="contact">
              <Trans
                i18nKey="cta.getInTouch"
                components={{ br: <br />, small: <small />, span: <span/> }}
              />
            </Link>
          </h2>
        </motion.div>
      </section>
    </div>
  );
}
