import { useEffect, useState } from "react"
import { useForm } from "@formcarry/react"

import {
  motion
} from "framer-motion";

import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Plausible from 'plausible-tracker'

import IconPlus from "../assets/plus";
import IconChevron from "../assets/chevron";
import AnimatedAppear from "../components/AnimatedAppear"
//import Form from "../components/Form"

import emojiBlink from "../assets/emojiBlink.png";
import emojiGlasses from "../assets/emojiGlasses.png";
import emojiHands from "../assets/emojiHands.png";

import "../styles/contact.scss"



/*const allPills = {
  Design: ["Photo & Video Production", "UI/UX Design", "3D Design", "Branding", "Merchandising"],
  Development: ["Software Development", "Website Maintenance", "Web Development", "App Development", "IT Consulting", "Artificial Intelligence"],
  Communication: ["Community Management", "Influencers & Celebrities", "Content Creation", "Market Research", "Lifestyle PR"],
  Strategy: ["Google & Social Ads", "Consulting and financial analysis", "SEO Positioning", "Email Marketing", "Digital Strategy", "Advertising Analytics"]
}*/

export default function Contact() {
  const { trackEvent } = Plausible()
  const { t, i18n } = useTranslation()

  const [submitted, setSubmitted] = useState(false)
  const [step, setStep] = useState(0)

  const [selectedServ, setSelectedServ] = useState([])
  const [selectedPills, setSelectedPills] = useState([])
  const [availablePills, setAvailablePills] = useState([])

  const [name, setName] = useState("")
  const [comp, setComp] = useState("")
  const [dl, setDl] = useState()
  const [email, setEmail] = useState("")
  const [tel, setTel] = useState("")
  const [msg, setMsg] = useState("")
  const [error, toggleError] = useState(false)

  const [newTag, setNewTag] = useState("")

  /*const { state, submit } = useForm({
    id: "TwRQI3cbY0"
  })*/

  const allPills = {
    Design: t("services.design").split(","),
    Development: t("services.development").split(","),
    Communication: t("services.communication").split(","),
    Strategy: t("services.strategy").split(",")
  }

  const options = [
    { value: 'Design', label: t("services.titleDesign"), imageUrl: '/potionDesign.jpg' },
    { value: 'Development', label: t("services.titleDev"), imageUrl: '/potionDev.jpg' },
    { value: 'Communication', label: t("services.titleCom"), imageUrl: '/potionCom.jpg' },
    { value: 'Strategy', label: t("services.titleStrat"), imageUrl: '/potionStrat.jpg' }/*,
    { value: 'IdontKnow', label: "I don't know", imageUrl: '/potionGrey.jpg' }*/
  ]



  useEffect(() => {
    if (step === 1) {
      let a = []
      selectedServ.forEach(elem => {
        a.push(...allPills[elem.value])
      })
      setAvailablePills(a)
    }
  }, [step])




  function send(e) {
    e.preventDefault()
    if (name && dl && tel) {
      toggleError(false)

      let _services = []

      selectedServ.forEach(serv => {
        _services.push(serv.label)
      })


      let sentTime = new Date().toLocaleTimeString('fr-fr', { month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })
      let _dl = new Date(dl).toLocaleDateString('fr-fr', { month: 'long', day: 'numeric', year: "numeric" })

      let hasDesign = false
      let hasDev = false
      let hasCom = false
      let hasStrat = false

      selectedServ.forEach(serv => {
        if (serv.value === "Development") hasDev = true
        else if (serv.value === "Design") hasDev = true
        else if (serv.value === "Communication") hasCom = true
        else if (serv.value === "strategy") hasStrat = true
      })

      trackEvent('Submit contact')

      fetch('https://hook.eu1.make.com/rryfx8xfyo8hoag937ey0yj756ggko2m', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          hasDesign, hasDev, hasCom, hasStrat,
          name: name ? name : "null",
          comp: comp ? comp : "null",
          email: email ? email : "null",
          tel: tel ? tel : "null",
          msg: msg ? msg : "null",
          dl: _dl,
          askedSpecs: selectedPills.length > 0 ? selectedPills.join(", ") : "null",
          sentTime
        })
      })
      .then(res => res.json())
      .then(res => console.log("Response from form back-end ->", res))

      setStep(3)
    } else {
      toggleError(true)
    }
  }


  const serviceChanged = (newVal, target) => {
    setSelectedServ(s => {
      if (newVal) {
        return [...s, ...options.filter(a => a.value === target)]
      } else {
        return [...s.filter(a => a.value !== target)]
      }
    })
  }

  const pillsChanged = (newVal, target) => {
    setSelectedPills(s => {
      if (newVal) {
        return [...s, target]
      } else {
        return [...s.filter(a => a !== target)]
      }
    })
  }

  const handleNewTag = () => {
    if (newTag?.length < 3) return

    setAvailablePills(s => [...s, newTag])
    setSelectedPills(s => [...s, newTag])
    return setNewTag("")
  }

  const confirmServices = e => {
    e.preventDefault()
    if (selectedPills.length === 0) return
      else return setStep(2)
  }
  
  const item = {
    minusThree: { x: 90, scale: .85, opacity: .5 },
    minusTwo: { x: 60, scale: .9, opacity: .9, zIndex: 7 },
    minusOne: { x: 30, scale: .95, zIndex: 9 },
    focus: { x: 0, zIndex: 10 },
    checked: { x: "-100vw", zIndex: 20 }
  }

  const transition = {
    delay: 0,
    type: "tween",
    ease: "easeIn",
    duration: .4,
  }


  let selectedLength = null
  if (Object.keys(selectedServ).length > 0) selectedLength = `(${Object.keys(selectedServ).length} ${Object.keys(selectedServ).length > 1 ? t("contact.selected") : t("contact.selectedOne")})`

  let selectedPill = null
  if (Object.keys(selectedPills).length > 0) selectedPill = `(${Object.keys(selectedPills).length} ${Object.keys(selectedPills).length > 1 ? t("contact.selected") : t("contact.selectedOne")})`

  //console.log(selectedPills)
  return (
    <AnimatedAppear>
      <section className="contact">
        <div className="contactFrame">

          <form onSubmit={send} className="cardsStack">

            <motion.div
              className="card"
              variants={item}
              animate={step === 0 ? "focus" : "checked"}
              transition={transition}
            >

              <h1><Trans i18nKey="contact.hi" components={{ br: <br/> }} /></h1>

              <div className="line">
                <span><Trans i18nKey="contact.chooseServices" components={{ br: <br/> }} /></span>
              </div>

              <div className="options">
                {options.map((option) => (
                  <ServicePill
                    label={option.label}
                    value={option.value}
                    img={option.imageUrl}
                    alt={option.label}
                    cb={serviceChanged}
                  />
                ))}
              </div>

              <button
                onClick={e => {e.preventDefault();setStep(selectedLength ? 1 : 0)}}
                className={selectedLength ? "active" : ""}
              ><Trans i18nKey="contact.next" /> {selectedLength}
              </button>
            </motion.div>


            <motion.div
              className="card"
              variants={item}
              animate={step === 0 ? "minusOne" : step === 1 ? "focus" : "checked"}
              transition={{...transition, delay: .3}}
            >
              <div className="back clickable" onClick={() => setStep(0)}><IconChevron/></div>
              <div className="skip" onClick={() => setStep(2)}><Trans i18nKey="contact.skip" /></div>

              <h1><Trans i18nKey="contact.title2" components={{ br: <br/> }} /></h1>

              <div className="forScrollOverflow" data-lenis-prevent>
                <div className="line">
                  <span><Trans i18nKey="contact.selectOneOrMore" /></span>
                  {availablePills?.length === selectedPills?.length
                    ? <small onClick={() => setSelectedPills([])}>
                      <Trans i18nKey="contact.unselect" />
                    </small>
                    : <small onClick={() => setSelectedPills(availablePills)} className="clickable">
                      <Trans i18nKey="contact.selectAll" />
                    </small>
                  }
                </div>

                <div className="options">
                  {availablePills.map((option) => (
                    <Pill
                      label={option}
                      value={option}
                      cb={pillsChanged}
                      isActive={selectedPills.includes(option)}
                    />
                  ))}
                </div>

                <div className="addServices">
                  <div className="line">
                    <span><Trans i18nKey="contact.serviceNotOnList" /></span>
                  </div>

                  <div
                    className="ctFrame"
                  >
                    <input
                      type="text"
                      placeholder={t("contact.addManually")}
                      value={newTag}
                      onChange={v => setNewTag(v.target.value)}
                    />
                    <button onClick={handleNewTag} className="clickable">
                      <IconPlus/>
                    </button>
                  </div>

                </div>

              </div>

              <button
                onClick={confirmServices}
                className={`clickable ${selectedPills.length > 0 ? "active" : ""}`}
              ><Trans i18nKey="contact.next" /> {selectedPill}
              </button>
            </motion.div>


            <motion.div
              className="card"
              variants={item}
              animate={step === 0 ? "minusTwo" : step === 1 ? "minusOne" : step === 2 ? "focus" : "checked"}
              transition={{...transition, delay: .3}}
            >
              <div className="back clickable" onClick={() => setStep(1)}><IconChevron/></div>

              <h1><Trans i18nKey="contact.tellUsAboutYou" /></h1>

              {error && <b className="error"><Trans i18nKey="contact.error"/></b>}

              <div className="inputs" data-lenis-prevent>
                <div>
                  <label htmlFor="name"><Trans i18nKey="contact.name"/>*</label>
                  <input id="name" type="text" name="name" placeholder={t("contact.name")} value={name} onChange={v => setName(v.target.value)} />
                </div>

                <div>
                  <label htmlFor="society"><Trans i18nKey="contact.company"/></label>
                  <input id="society" type="text" name="society" value={comp} onChange={v => setComp(v.target.value)}/>
                </div>

                <div>
                  <label htmlFor="target"><Trans i18nKey="contact.target"/>*</label>
                  <input id="target" type="date" name="target" value={dl} onChange={v => setDl(v.target.value)}/>
                </div>

                <div>
                  <label htmlFor="email"><Trans i18nKey="contact.email"/></label>
                  <input id="email" type="email" name="email" placeholder={t("contact.emailPh")} value={email} onChange={v => setEmail(v.target.value)}/>
                </div>

                <div>
                  <label htmlFor="tel"><Trans i18nKey="contact.tel"/>*</label>
                  <input id="tel" type="tel" name="tel" placeholder={t("contact.telPh")} value={tel} onChange={v => setTel(v.target.value)}/>
                </div>

                <div className="row">
                  <label htmlFor="message"><Trans i18nKey="contact.message"/></label>
                  <textarea
                    id="message"
                    name="message"
                    placeholder={t("contact.messagePh")}
                    value={msg}
                    onChange={v => setMsg(v.target.value)}
                  />
                </div>
              </div>

              <div className="after"/>

              <button className="clickable active">
                <Trans i18nKey="contact.send"/>
              </button>
              {/*<Form callback={v => setSubmitted(v)}/>*/}
            </motion.div>


            <motion.div
              className="card"
              variants={item}
              animate={step === 0 ? "minusThree" : step === 1 ? "minusTwo" : step === 2 ? "minusOne" : step === 3 ? "focus" : "checked"}
              transition={{...transition, delay: .3}}
            >
              {step == 3 ? <div className="emojis">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                    rotate: -20,
                    scale: 3
                  }}
                  transition={{
                    duration: .5,
                    delay: 1
                  }}
                >
                  <img src={emojiHands} alt="emoji"/>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                    rotate: 20,
                    scale: 3
                  }}
                  transition={{
                    duration: .5,
                    delay: 1.7
                  }}
                >
                  <img src={emojiBlink} alt="emoji"/>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                    rotate: 10,
                    scale: 3
                  }}
                  transition={{
                    duration: .5,
                    delay: 1.9
                  }}
                >
                  <img src={emojiGlasses} alt="emoji"/>
                </motion.div>
              </div> : <div className="emojis"/>}

              <div className="setUp">
                <h1><Trans i18nKey="contact.allIsSetUp"/></h1>
                <span><Trans i18nKey="contact.messageReceived"/></span>
                <Link to="/"><button /*onClick={() => setStep(2)}*/><Trans i18nKey="contact.home"/></button></Link>
              </div>
              
            </motion.div>

            <div className="card forSize"/>
          </form>

          
        </div>
      </section>
    </AnimatedAppear>
  );
}







function ServicePill(p) {
  const [checked, setChecked] = useState(false)

  const handleChange = () => {
    setChecked(!checked)
    p.cb(!checked, p.value)
  }

  return (
    <label for={p.value} class={`clickable servicePill ${checked ? "checked" : ""}`}>
      <input
        class="checkbox"
        id={p.value}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
      />
      <img src={p.img} alt={p.alt}/>
      <span>{p.label}</span>
    </label>
  )
}








function Pill(p) {
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    if (p.isActive && !checked) setChecked(true)
    else if (!p.isActive && checked) setChecked(false)
  }, [p.isActive])

  const handleChange = () => {
    setChecked(!checked)
    p.cb(!checked, p.value)
  }

  return (
    <label for={p.value} class={`clickable pill ${checked ? "checked" : ""}`}>
      <input
        class="checkbox"
        id={p.value}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
      />
      <span>{p.label}</span>
    </label>
  )
}