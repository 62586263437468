import { useState } from "react";

import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom"

import Chevron from "../assets/chevron";

import "../styles/footer.scss";

export default function Footer() {
  const { t, i18n } = useTranslation();

  const [toggled, toggle] = useState(false);

  function changeLanguage(target) {
    return i18n.changeLanguage(target);
  }

  let actualLang = i18n.language.slice(0, 2).toUpperCase()
  
  return (
    <footer>
      <div className="left">
        <span>©DSTUDIO 2023</span>
        <Link to="/legal">CGU</Link>
        <span>{t("footer.connectOnSocial")}</span>
      </div>

      <div className="center" />

      <div className="right">
        <a
          href="https://www.instagram.com/dstudio.company"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram
        </a>
        <a
          href="https://www.tiktok.com/@dstudio.company"
          target="_blank"
          rel="noopener noreferrer"
        >
          TikTok
        </a>
        <a
          href="https://www.linkedin.com/company/d-studio-company"
          target="_blank"
          rel="noopener noreferrer"
        >
          Linkedin
        </a>

        <div className="langFrame">
          <div
            className={`lang ${toggled ? "open" : ""}`}
            onClick={() => toggle(!toggled)}
          >
            <div className="main">
              {actualLang}
              <Chevron />
            </div>
            <div className="choice">
              {actualLang !== "FR" && (
                <span onClick={() => changeLanguage("fr")}>FR</span>
              )}
              {actualLang !== "EN" && (
                <span onClick={() => changeLanguage("en")}>EN</span>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="left mobile">
        <span />
        <span>©DSTUDIO 2023</span>
      </div>
    </footer>
  );
}
